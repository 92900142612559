<script>
  import Base from '@backend/Base.vue';
  import BOGen from '@helper/BOGen';
  import Gen from '@helper/Gen';
  import draggable from 'vuedraggable'

  export default {
    name: "BoRecipeCat",
    extends: Base,
    components: {
      draggable
    },
    data() {
      return {
        Name: "BoRecipeCat",
        hero: {},
        titleLength:0,
      }
    },
    mounted() {
      this.$set(this.$root, 'page', this)
      this.refreshData()
    },
    methods: {
      endDrag() {
        BOGen.apirest('/' + this.Name, {
          data: this.data.data,
          type: 'sort'
        }, (err, resp) => {
          console.log(resp)
        }, "POST");
      },
      submitHero() {
        this.hero.type = 'submitHero'
        BOGen.apirest('/' + this.Name, this.hero, (err, resp) => {
          if (err) return Gen.info((err.resp.message || "please fill mandatory fields"), "danger", 3000,
            ".col-info")
          Gen.info((resp.message), "success", 3000, ".col-info")
          setTimeout(() => {
            $('#collapseExample').hide()
          }, 3000);
        }, "POST");
      },
      showEdit() {
        if ($("#collapseExample").css("display") == "flex")
          $('#collapseExample').hide()
        else
          $('#collapseExample').css("display", "flex")
      }
    },
    watch: {
      '$route.query'(v) {
        this.refreshData()
      },
      'row.mrc_name'(v){
        this.titleLength = v.length
      }
    }
  }
</script>

<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>

    <div class="row">
      <div class="col-12" v-if="!$route.params.id">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Hero Image
            </h5>
          </div>
          <VForm @resp="submitHero" method="post">
            <div class="card-body">
              <div class="alert alert-info">
                Image Requirements for Hero Image :
                <ul class="mb-0 mt-2">
                  <li><strong>Dimension: </strong><span>1350x420px (Desktop), 768x1285px (Mobile)</span></li>
                  <li><strong>Format: </strong><span>jpeg,jpg,png</span></li>
                  <li><strong>Max. Size: </strong><span>2 MB</span></li>
                </ul>
              </div>
              <div class="row">
                <div class="col-md-12"></div>
                <div class="col-md-8">
                  <div class="wrap_slider_img">
                    <img :src="uploader(hero.ash_img)" class="img-responsive" />
                    <div class="slider_name">
                      <p>{{hero.ash_title}}</p>
                    </div>
                    <a href="javascript:;" @click="showEdit" class="bullet_edit"><i class="ti-marker-alt"></i></a>
                  </div>
                </div>
              </div>
              <div class="row collapse mt-4" id="collapseExample">
                <div class="col-info col-12"></div>
                <div class="col-sm-3 mb-3">
                  <BoField name="ash_mobile_img">
                    <Uploader v-bind="validation('ash_mobile_img')" :attr="{required:1}"
                      :param="{thumbnail:true,img_ratio:768/1285,rule_size:[768,1285]}" name="ash_mobile_img"
                      v-model="hero.ash_mobile_img" type="hero_mobile" uploadType="cropping"></Uploader>
                  </BoField>
                </div>
                <div class="col-sm-9">
                  <BoField name="ash_img">
                    <Uploader v-bind="validation('ash_img')" :attr="{required:1}" :param="{thumbnail:true}"
                      name="ash_img" v-model="hero.ash_img" type="hero_desktop_420" uploadType="cropping"></Uploader>
                  </BoField>
                </div>
                <div class="col-4 col-md-3 mt-3">
                  <button type="submit" class="btn btn-rounded btn-block btn-info btn-loading">Submit</button>
                </div>
              </div>
            </div>
          </VForm>
        </div>
      </div>

      <div class="col-12" v-if="!$route.params.id">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Recipe Category
              <small class="card-subtitle">Drag list to move the position.</small>
            </h5>
          </div>
          <div class="card-body">
            <div class="table-responsive ajax-table">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>
                      <SortField name="mrc_name"></SortField>
                    </th>
                    <th>Status</th>
                    <th width="120px">Action</th>
                  </tr>
                </thead>
                <draggable v-model="data.data" tag="tbody" @end="endDrag" style="cursor:pointer;">
                  <tr v-for="(v,k) in data.data" :key="k">
                    <td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
                    <td>{{v.mrc_name}}</td>
                    <td>
                      <StatusLabel :status="v.status"></StatusLabel>
                    </td>
                    <td class="btn-action">
                      <router-link class="icon_action" :to="{name:Name,params:{id:v.id}}" v-tooltip="'Edit'"><i
                          class="ti-marker-alt"></i></router-link>
                      <a href="javascript:;" class="icon_action" @click="changeStatus(k)" v-tooltip="'Change Status'"><i
                          class="icon-settings"></i></a>
                      <a v-if="v.mrc_id != 1" href="javascript:;" class="icon_action" @click="deleteItem($event,k)"
                        v-tooltip="'Remove'"><i class="ti-trash"></i></a>
                    </td>
                  </tr>
                  <tr v-if="NotFound">
                    <td colspan="99">
                      <h3 class="tc">{{NotFound}}</h3>
                    </td>
                  </tr>
                  <tr v-if="data.data===false">
                    <td colspan="99">
                      <LoadingSpinner light></LoadingSpinner>
                    </td>
                  </tr>
                </draggable>
              </table>
            </div>
            <div class="panel-body">
              <div class="pull-right">
                <Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12" v-if="$route.params.id">
        <VForm @resp="submitForm" method="post">
          <div class="card">
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
              </div>
              <hr>
              <div class="row">
                <div class="col-12 info"></div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-12">
                      <BoField name="mrc_name" v-model="row.mrc_name"></BoField>
                      <small class="mb-2 d-inline-block text-info">{{validation('mrc_name').maxlength-titleLength}} character remaining </small>
                      <BoField name="mrc_is_active">
                        <div class="row">
                          <div class="col-2">
                            <radio name="mrc_is_active" v-model="row.mrc_is_active" option="Y"
                              :attr="validation('mrc_is_active')">Active</radio>
                          </div>
                          <div class="col-2">
                            <radio name="mrc_is_active" v-model="row.mrc_is_active" option="N">Inactive</radio>
                          </div>
                        </div>
                      </BoField>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row mb-3">
                    <div class="col-md-8">
                      <h5 class="card-title">Meta Content</h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <BoField name="mrc_seo_title" :attr="{minlength:'3'}" v-model="row.mrc_seo_title" mandatory>
                      </BoField>
                      <BoField name="mrc_seo_keyword" mandatory>
                        <TagsInput name="mrc_seo_keyword" v-model="row.mrc_seo_keyword"
                          :attr="validation('mrc_seo_keyword')"></TagsInput>
                      </BoField>
                    </div>
                    <div class="col-sm-12">
                      <BoField name="mrc_seo_desc" mandatory>
                        <textarea name="mrc_seo_desc" rows="5" :attr="{minlength:'10'}" v-model="row.mrc_seo_desc"
                          v-bind="validation('mrc_seo_desc')" class="form-control"></textarea>
                      </BoField>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div class="d-flex justify-content-end">
                <button type="submit" class="btn btn-rounded btn-info btn-loading">Submit</button>
              </div>
            </div>
          </div>
        </VForm>
      </div>
    </div>
  </div>
</template>